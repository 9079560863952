import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import './styles.scss'

const Image = loadable(() => import('/src/components/Media/Image'))

const Testimonials = ({ data }) => {
    return (
        <div className="c-testimonials">
            <div className="row">
                {data.map((node, index) => {
                    return (
                        <div
                            key={index}
                            className="col-lg-6 c-testimonials__item"
                        >
                            <Image
                                data={node.image}
                                className={'c-testimonials__item-image'}
                            />

                            <blockquote className="c-testimonials__item-quote">
                                {parse(node.content)}
                            </blockquote>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

Testimonials.propTypes = {
    /**
     * Data
     */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object,
            content: PropTypes.string
        })
    ).isRequired
}

Testimonials.defaultProps = {
    data: [
        {
            image: {},
            content: 'Testimonial here'
        },
        {
            image: {},
            content: 'Testimonial here'
        }
    ]
}

export default Testimonials
